import React from 'react';

import {PageWrapper} from '../components/templates';
import {Button} from '../components/common';
import {Column, LayoutContainer} from '../components/layouts';
import kennel from '../images/kennel.svg';
import {RCDL_VERSION} from '../config';

/**
 * @description  Page template for '404' page.
 * @return {DOMElement} 404 page React element.
 */
const NotFoundPage = () => {
  return (
    <>
      <PageWrapper pageContext={{title: '404 not found'}}>
        <LayoutContainer
          columns="one"
          additionalClass='rc-max-width--xl rc-text--center
            rc-padding-bottom--md rc-padding-top--md rc-margin-bottom--md'>
          <Column>
            <div className="spacer-medium text--centered bg-colour--interface">
              <div className="rc-max-width--sm rc-margin-bottom--md">
                <object data={kennel} type="image/svg+xml" className="svg--animated">
                  <img
                    src={`https://d1a19ys8w1wkc1.cloudfront.net/1x1.gif?v=${RCDL_VERSION}`}
                    style={{backgroundImage: `url(${kennel})`}} alt="kennel" />
                </object>
              </div>
              <h1 className="rc-alpha">You seem to be lost...</h1>
              <p>The page you&apos;re trying to access doesn&apos;t exist. <br />
              To find what you&apos;re looking for, go to the homepage.</p>
              <Button tag="link" href="/" type="two">Take me home</Button>
            </div>
          </Column>
        </LayoutContainer>
      </PageWrapper>
    </>
  );
};

export default NotFoundPage;
